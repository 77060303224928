@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin { 
  100% { 
      transform:rotate(360deg); 
  } 
}

.App {
  text-align: center;
  background-color: #fff;
  background-image: url("https://haystackdevstorage.blob.core.windows.net/img/pexels-nikiemmert-9141262.jpg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
